import React, { useState, useEffect } from 'react';
import {  Container, Button  } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';




const Annoucement = () => {
    const [isSticky, setIsSticky] = useState(false);

    return (
        <Container fluid className='bg-beforefooter' style={{position:"relative"}}>
            <div className='overlay-home-countdown' >
               
                <div className='verse-annouce'>

                    <Row>
                        <Col xs={12} sm={12}>
                            <h3>countdoen timer here for events</h3>
                           
                        </Col>
                       
                    </Row>
                </div>
            
              
            </div>
        </Container>
    );

};

export default Annoucement;