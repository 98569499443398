import React, { useState, useEffect } from 'react';
import {  Container, Button  } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import EventListing from './EventListing';
import BlogPost from './BlogPost';
import ImageOne from "../../../images/footer/image_1.jpg";
import ImageTwo from "../../../images/footer/image_2.jpg";
import { useFirebase } from '../../../Context/FirebaseContext';
import { collection, query, where, orderBy, limit, getDocs } from 'firebase/firestore';
import SlugGenerator from '../../../Components/Helpers/SlugGenerator';
import AbbreviatedMonth from '../../../Components/Helpers/AbbreviatedMonth';

const BlogUpdate = () => {
    const [isSticky, setIsSticky] = useState(false);
    const [recentPosts, setRecentPosts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [upcomingEvents, setUpcomingEvents] = useState([]);
    const { db } = useFirebase();

    useEffect(() => {
        const fetchRecentPosts = async () => {

        try {
            const blogCollection = collection(db, 'blog');
            const recentPostsQuery = query(
                blogCollection,
                where('status', '==', 'publish'),
                orderBy('createdOn', 'desc'),
                limit(3)
            );

            const querySnapshot = await getDocs(recentPostsQuery);
            const posts = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));

            setRecentPosts(posts);
            setLoading(false);
        } catch (err) {
            console.error('Error fetching recent posts:', err);
            setError(err);
            setLoading(false);
        }
    };

    fetchRecentPosts();
    }, [db]);

    
    const createSlug = (string) => {
      string = string.toLowerCase();
      string = string.replace(/[^a-z0-9 ]/g, '');
      string = string.replace(/\s+/g, '-');
      string = string.replace(/^-|-$/g, '');
      return string === '' ? 'slug' : string;
    };

  

    useEffect(() => {
        const fetchUpcomingEvents = async () => {
          try {
            const today = new Date();
            const dateString = today.toISOString().split('T')[0]; // "YYYY-MM-DD"
            const timeString = today.toTimeString().split(' ')[0]; // "HH:MM:SS"
            //console.log(dateString);
    
            const eventsCollection = collection(db, 'events');
            const eventsQuery = query(
              eventsCollection,
              where('status', '==', 'active'),
              where('endDate', '>=', dateString),
              orderBy('endDate', 'asc'),
              orderBy('endTime', 'asc'),
              limit(3)
            );
    
            const querySnapshot = await getDocs(eventsQuery);
            const events = querySnapshot.docs.map(doc => ({
              id: doc.id,
              ...doc.data()
            }));
    
            //console.log(events);
            setUpcomingEvents(events);
            setLoading(false);
          } catch (err) {
            console.error('Error fetching upcoming events:', err);
            setError(err);
            setLoading(false);
          }
        };
    
        fetchUpcomingEvents();
      }, [db]);
    
      const renderAuthorName = (author) => {
        if (typeof author === 'string') {
          return author; // For backwards compatibility
        }
        return author.name || 'Unknown Author';
      };
    

    return (
        <Container>
            <Row style={{ paddingTop:"50px", paddingBottom:"40px"}}>
                <Col xs={12} sm={12}><h1 style={{ textAlign:"left", marginBottom:"50px"}}>Blog & Events Updates</h1></Col>
                <Col xs={12} sm={12} md={8} style={{overflow:"hidden", paddingRight:"30px", paddingBottom:"50px"}} className='truncated-text' >
                  <h3 className='small-heading'>Lastest Blogs</h3>
                    {recentPosts.map((item, index) => (
                        <BlogPost 
                            key={index}
                            imageUrl={item.imageUrl}
                            href={`/blog/${createSlug(item.title)}/${item.id}`}
                            alt =  {<SlugGenerator title={item.title} />}
                            title={item.title}
                            date={item.date}
                            author={renderAuthorName(item.author)}
                            
                        />
                    ))}

                </Col >
                <Col xs={12} sm={12} md={4}>

                    <h3 className='small-heading'>Events</h3>
                    {upcomingEvents.map((item, index)=>(
                        <EventListing 
                            key={index}
                            month={item.startDate}
                            day={item.startDate}
                            title={item.eventName}
                            href={`/event/eventview/${item.id}/${createSlug(item.eventName)}`}
                            startDate= {item.startDate}
                            endDate={item.endDate}
                        />
                    ))}
                    
                </Col >
            </Row>
        </Container>
    );

};

export default BlogUpdate;