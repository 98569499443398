import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FaFacebookF, FaXTwitter, FaSquareInstagram  } from "react-icons/fa6";
import SocialIcons from './SocialIcons';
import BlogPost from './BlogPost';
import ImageOne from "../../images/footer/image_1.jpg";
import ImageTwo from "../../images/footer/image_2.jpg";
import QuickLinks from './QuickLinks';
import ContactInfo from './ContactInfo';


const Footer = () => {

  const footerLinks = [
    { text: 'HOME', href: '#' },
    { text: 'ABOUT US', href: '#' },
    { text: 'GROUPS', href: '#' },
    { text: 'SERMONS', href: '#' },
    { text: 'CONTACT', href: '#' },
    { text: 'LOCATION', href: '#' },
  ];

  return (
    <footer className="bg-dark text-white py-3" style={{paddingTop:"40px", paddingLeft:"30px", paddingRight:"30px"}}>
      <Container fluid>
        <div style={{ paddingBottom: "20px", paddingTop:"15px", marginTop:"70px", paddingBottom:"50px", overflow:"hidden"}}>
          <Row>
              <Col xs={12} md={3} style={{paddingBottom:"15px"}}>
                  <p className='footer-heading'>EMMANUEL DIST. CONG.</p>
                  <p style={{textAlign:"left", color:"rgba(255, 255, 255, 0.7)"}}>
                  Lorem ipsum dolor sit amet, consectetur 
                  adipiscing elit. Ut elit tellus, luctus nec 
                  ullamcorper mattis, pulvinar dapibus leo. 
                  Lorem ipsum dolor sit amet, consectetur 
                  adipiscing elit. Ut elit tellus, luctus nec 
                  ullamcorper mattis, pulvinar dapibus leo.
                  </p>
                  <SocialIcons/>
              </Col>
              <Col xs={12} md={3}>
                  <p className='footer-heading'>Lastest News</p>
                  <div>
                  <BlogPost 
                      imageUrl={ImageTwo}
                      title="Even the all-powerful Pointing has no control about"
                      date="Aug. 10, 2020"
                      author="Admin"
                      comments="19"
                    />
                    <BlogPost 
                      imageUrl={ImageOne}
                      title="Even the all-powerful Pointing has no control about"
                      date="Aug. 10, 2020"
                      author="Admin"
                      comments="19"
                    />
                    <BlogPost 
                      imageUrl={ImageTwo}
                      title="Even the all-powerful Pointing has no control about"
                      date="Aug. 10, 2020"
                      author="Admin"
                      comments="19"
                    />
                  </div>
              </Col>
              <Col xs={12} md={3}>
                <p className='footer-heading'>Quick Links</p>
                <QuickLinks links={footerLinks} />
                 
              </Col>
              <Col xs={12} md={3}>
                  <p className='footer-heading truncated-text'>HAVE A QUESTIONS?</p>
                
                  <ContactInfo /> 
              </Col>
          </Row>
        </div>
        <Row>
          <Col xs={12} md={6}>
            {/* Your left-side content, like copyright notice */}
            <p style={{textAlign:"left", color:"rgba(255, 255, 255, 0.7)"}}>&copy; 2024 WILBELANN WEBSITE LAYOUT DEMO</p>
          </Col>
          <Col xs={12} md={6} className="d-flex justify-content-end">
            {/* Your right-side content, like social media links */}
            <a href="#" className="text-white mx-2">
              <i className="bi bi-facebook" />
            </a>
            <a href="#" className="text-white mx-2">
              <i className="bi bi-twitter" />
            </a>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
