// home.jsx
import React from 'react';
import NavBarComponent from '../Layouts/IndexLayout/Nav/Nav';
import { Container } from 'react-bootstrap';
import AudioBannerComponent from '../Layouts/IndexLayout/Audio/AudioBannerComponent';
import Motto from '../Layouts/IndexLayout/Motto/Motto';
import Welcome from '../Layouts/IndexLayout/Welcome/Welcome';
import OurGroups from '../Layouts/IndexLayout/Groups/OurGroups';
import Footer from '../Components/Footer/Footer';
import CallToAction from '../Layouts/IndexLayout/CallToAction/CallToAction';
import BlogUpdate from '../Layouts/IndexLayout/Blog/BlogUpdate';
import BibleVerse from '../Layouts/IndexLayout/Bible/BibleVerse';
import Schedule from '../Layouts/IndexLayout/Activity/Schedule';
import Annoucement from '../Layouts/IndexLayout/Annoucements/Annoucement';
import BannerCarouselComponent from '../Layouts/IndexLayout/Carousel/BannerCarouselComponent';
import FadeInSection from '../Components/FadeInSection';
import SEO from '../Components/SEO';

const Home = () => {
  return (
    <>
        <SEO
            title='EPC Kasoa'
            description='The official website of the Emmanuel Presbyterian Church Kasoa'
            name='Emmnuel Presbyterian Church.'
            type='profile' 
        />
        <NavBarComponent />
        <BannerCarouselComponent />
        <FadeInSection>
            <AudioBannerComponent />
        </FadeInSection>

        <Container className='container-overflow-width ' style={{paddingTop:"20px"}}>
    
            <FadeInSection>
            <Motto />
            </FadeInSection>
        
            <FadeInSection>
            <Welcome />
            </FadeInSection>
        
            <FadeInSection>
            <OurGroups/>
            </FadeInSection>
    
        </Container>

        <Container fluid style={{  textAlign:"left", minHeight:"50px", color:"whitesmoke"}} className='bg-dark container-overflow-width'>
            <FadeInSection>
            <CallToAction/>
            </FadeInSection>
        </Container>

        <Container  style={{  textAlign:"left"}} className='container-overflow-width '>
            <FadeInSection>
            <BlogUpdate />
            </FadeInSection>
        </Container>
            
        <FadeInSection>
            <BibleVerse />
        </FadeInSection>
        
        <FadeInSection>
            <Schedule/>
        </FadeInSection>
        
        <FadeInSection>
            <Annoucement />
        </FadeInSection>
    
        <Footer/>

    </>
  );
};

export default Home;

