import React, { useState, useEffect } from 'react';
import {  Container, Button  } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import UpcomingService from './UpcomingService';
import Announcement from './Announcement';
import FadeInSection from '../../../Components/FadeInSection'; 


const Schedule = () => {
    const [isSticky, setIsSticky] = useState(false);

    const announcements = [
        "All group leaders are to submit the names of all their members by the 1st of February.",
        "Church cloth is available for 120gh a yard. Please group leaders inform your team.",
        "Offertory collection for both services amounted to 22,000.00.",
        "Madam Kumi and her family are thanking God with 1000gh for safe travels during their funeral.",
        "Next week's Bible study will focus on the Book of Romans.",
        "The church choir is looking for new members. Please see Sister Mary after the service if interested."
      ];

    return (
        <Container fluid className='bg-dark' style={{width: "100%", overflowX:"hidden"}}>
            <Container className='activity text-white' style={{paddingBottom:"100px"}}>
                <Row style={{ paddingTop:"40px", paddingBottom:"20px"}}>
                    <Col xs={12} sm={12}><h1 className='text-white' style={{ textAlign:"left", marginBottom:"50px"}}>Service Update and Notices </h1></Col>
                    <Col xs={12} sm={12} md={4}>
                        <FadeInSection direction="left">
                        <UpcomingService />
                        </FadeInSection>
                    </Col>
                    <Col xs={12} sm={12} md={8}>
                        <FadeInSection direction="right">
                            <Announcement 
                                date="January 28, 2023"
                                announcements={announcements}
                                importantNote="Remember, our annual church picnic is coming up in two weeks. Don't forget to sign up!"
                            />
                        </FadeInSection>
                    </Col>
                
                </Row>
            </Container>
          
        </Container>
    );

};

export default Schedule;