import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useFirebase } from '../Context/FirebaseContext';
import { collection, query, where, getDocs, orderBy, limit, startAfter } from 'firebase/firestore';
import { Container, Spinner } from 'react-bootstrap';
import PageHeader from '../Layouts/PageHeader/PageHeader';
import NavBarComponent from '../Components/GeneralNav/Nav';
import SEO from '../Components/SEO';
import { FaCalendarAlt } from 'react-icons/fa';
import { Fa1, Fa2, Fa3, Fa4, Fa5, Fa6, Fa7, Fa8, Fa9, Fa0 } from "react-icons/fa6";
import Footer from '../Components/Footer/Footer';
import Pagination from 'react-bootstrap/Pagination';
import HeaderImage from "../images/headers/s2.jpg";
import styled from 'styled-components';

const NoticeCard = styled.div`
  background-color: var(--bodybgcolorwhite);
  border-radius: 12px;
  padding: 30px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  max-width: 700px;
  margin: 20px auto;

  @media (max-width: 576px) {
    padding: 20px;
  }
`;

const Title = styled.h1`
  text-align: center;
  font-size: 28px;
  margin-bottom: 20px;
  border-bottom: 2px solid var(--redcolor);
  padding-bottom: 10px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;

  @media (max-width: 576px) {
    font-size: 22px;
  }
`;

const NoticeList = styled.ul`
  list-style-type: none;
  padding: 0;
`;

const NoticeItem = styled.li`
  margin-bottom: 20px;
  display: flex;
  align-items: flex-start;
`;

const Icon = styled.span`
  margin-right: 15px;
  color: red;
  font-size: 24px;
  flex-shrink: 0;

  @media (max-width: 576px) {
    font-size: 20px;
    margin-right: 10px;
  }
`;

const NoticeText = styled.p`
  margin: 0;
  font-size: 16px;
  text-align: left;

  @media (max-width: 576px) {
    font-size: 14px;
  }
`;

const Date = styled.p`
  color: gray;
  font-size: 14px;
  margin-bottom: 10px;

  @media (max-width: 576px) {
    font-size: 12px;
  }
`;

const Notices = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { db } = useFirebase();
    const [notices, setNotices] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [lastVisible, setLastVisible] = useState(null);
    const noticesPerPage = 5;

    useEffect(() => {
        fetchNotices();
    }, [currentPage]);

    const fetchNotices = async () => {
        setLoading(true);
        setError(null);
        try {
            const today = new window.Date();
            today.setHours(0, 0, 0, 0);

            let noticesQuery = query(
                collection(db, 'notices'),
                where('status', '==', 'active'),
                orderBy('endDate', 'asc'),
                limit(noticesPerPage)
            );

            if (lastVisible && currentPage > 1) {
                noticesQuery = query(
                    collection(db, 'notices'),
                    where('status', '==', 'active'),
                    orderBy('endDate', 'asc'),
                    startAfter(lastVisible),
                    limit(noticesPerPage)
                );
            }

            const querySnapshot = await getDocs(noticesQuery);
            const fetchedNotices = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));

            setNotices(fetchedNotices);
            setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);

            if (currentPage === 1) {
                const totalDocs = await getDocs(query(
                    collection(db, 'notices'),
                    where('status', '==', 'active')
                ));
                setTotalPages(Math.ceil(totalDocs.size / noticesPerPage));
            }
        } catch (err) {
            setError('Failed to fetch notices. Please try again later.');
            console.error('Error fetching notices:', err);
        } finally {
            setLoading(false);
        }
    };

    const getIcon = (index) => {
        const icons = [Fa1, Fa2, Fa3, Fa4, Fa5, Fa6, Fa7, Fa8, Fa9, Fa0];
        return icons[index % 10];
    };

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
        navigate(`/notices?page=${pageNumber}`);
    };

    return (
        <>
            <SEO
                title="Notices"
                description="View our latest notices and announcements"
                name='Emmanuel Presbyterian Church.'
                type='notices' 
            />
            <NavBarComponent />
            <PageHeader 
                title="Notices" 
                backgroundImage={HeaderImage}
            />
            <div style={{marginBottom:"100px"}}></div>

            <Container className='alignleft text-dark nobg'>
                <NoticeCard>
                   
                    {loading ? (
                        <div className="text-center">
                            <Spinner animation="border" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </Spinner>
                        </div>
                    ) : error ? (
                        <div className="alert alert-danger" role="alert">
                            {error}
                        </div>
                    ) : notices.length === 0 ? (
                        <div className="text-center">
                            <h2>No active notices at the moment.</h2>
                        </div>
                    ) : (
                        <NoticeList>
                            {notices.map((notice, index) => {
                                const IconComponent = getIcon(index);
                                return (
                                    <NoticeItem key={notice.id}>
                                        <Icon><IconComponent /></Icon>
                                        <div>
                                            <Date>
                                                <FaCalendarAlt className="me-2" />
                                                <span>End Date: {notice.endDate}</span>
                                            </Date>
                                            <NoticeText>{notice.notice}</NoticeText>
                                        </div>
                                    </NoticeItem>
                                );
                            })}
                        </NoticeList>
                    )}

                    {totalPages > 1 && (
                        <Pagination className="justify-content-center mt-4">
                            <Pagination.First onClick={() => handlePageChange(1)} disabled={currentPage === 1} />
                            <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} />
                            
                            {[...Array(totalPages)].map((_, index) => (
                                <Pagination.Item
                                    key={index + 1}
                                    active={index + 1 === currentPage}
                                    onClick={() => handlePageChange(index + 1)}
                                >
                                    {index + 1}
                                </Pagination.Item>
                            ))}
                            
                            <Pagination.Next onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} />
                            <Pagination.Last onClick={() => handlePageChange(totalPages)} disabled={currentPage === totalPages} />
                        </Pagination>
                    )}
                </NoticeCard>
            </Container>
            <div style={{marginTop:"20px",marginBottom:"50px"}}></div>
            <Footer />
        </>
    );
};

export default Notices;