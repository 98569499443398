import React, { useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { FaQuoteLeft } from 'react-icons/fa'; // Importing the icon from react-icons
import "./motto.css";

const Motto = () => {
  const [isSticky, setIsSticky] = useState(false);

  return (
    <Container className="motto-container">
      <blockquote className="blockquote">
        <FaQuoteLeft className="quote-icon" /> {/* Adding the icon */}
        <p className='motto'>
          “to be a Christ centered self sustaining and a growing church”
        </p>
      </blockquote>
    </Container>
  );
};

export default Motto;
