import React from 'react';
import NavBarComponent from '../Components/GeneralNav/Nav';
import Footer from '../Components/Footer/Footer';
import { Container } from 'react-bootstrap';

const Contact = () => {
  return (
    <>
      <NavBarComponent />
      <Container style={{marginTop:"60px"}}>
        <h2>Contact</h2>
        <p>This is the Contact page.</p>
      </Container>
      <Footer />
    </>
  );
};

export default Contact;