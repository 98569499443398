import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useFirebase } from '../../../Context/FirebaseContext';
import { doc, getDoc } from 'firebase/firestore';
import './bible.css';

const BibleVerse = () => {
  const { db } = useFirebase();
  const [verse, setVerse] = useState('');
  const [book, setBook] = useState('');

  useEffect(() => {
    const fetchVerse = async () => {
      try {
        const docRef = doc(db, 'verse', 'current');
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const data = docSnap.data();
          setVerse(data.verse);
          setBook(data.book);
        } else {
          console.log('No such document!');
        }
      } catch (error) {
        console.error('Error fetching verse:', error);
      }
    };

    fetchVerse();
  }, [db]);

  return (
    <Container fluid className='bg-verse bible-verse-box'>
      <div className='overlay-home'>
        <Container>
          <blockquote>
            <Row style={{ paddingTop: "30px", paddingBottom: "20px" }}>
              <Col xs={12} sm={12}>
                <h1 className='text-white'>Bible verse of the week</h1>
              </Col>
              <Col xs={12} sm={12}>
                <p className='text-white' style={{ fontStyle: "italic" }} id="verse">
                  {verse || 'Loading...'}
                </p>
                <p className='text-white' style={{ fontStyle: "italic" }} id="book">
                  {book || 'Loading...'}
                </p>
              </Col>
            </Row>
          </blockquote>
        </Container>
      </div>
    </Container>
  );
};

export default BibleVerse;