import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Fa1, Fa2, Fa3, Fa4, Fa5, Fa6, Fa7, Fa8, Fa9, Fa0 } from "react-icons/fa6";
import { useFirebase } from '../../../Context/FirebaseContext';
import { collection, query, where, getDocs, orderBy } from 'firebase/firestore';

const AnnouncementCard = styled.div`
  background-color: var(--bodybgcolorwhite);
  border-radius: 12px;
  padding: 30px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  max-width: 600px;
  margin: 20px auto;

  @media (max-width: 576px) {
    padding: 20px;
  }
`;

const Title = styled.h1`
  text-align: center;
  font-size: 28px;
  margin-bottom: 20px;
  border-bottom: 2px solid var(--redcolor);
  padding-bottom: 10px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;

  @media (max-width: 576px) {
    font-size: 22px;
  }
`;

const Date = styled.h2`
  color: gray;
  text-align: left;
  font-size: 14px;
  margin-bottom: 20px;

  @media (max-width: 576px) {
    font-size: 12px;
  }
`;

const AnnouncementList = styled.ul`
  list-style-type: none;
  padding: 0;
`;

const AnnouncementItem = styled.li`
  margin-bottom: 20px;
  display: flex;
  align-items: flex-start;
`;

const Icon = styled.span`
  margin-right: 15px;
  color: red;
  font-size: 24px;
  flex-shrink: 0;

  @media (max-width: 576px) {
    font-size: 20px;
    margin-right: 10px;
  }
`;

const AnnouncementText = styled.p`
  margin: 0;
  font-size: 16px;
  text-align: left;

  @media (max-width: 576px) {
    font-size: 14px;
  }
`;

const ImportantNote = styled.div`
  background-color: var(--bodybgcolorgrey);
  border-left: 4px solid red;
  padding: 15px;
  margin-top: 20px;
  border-radius: 4px;

  @media (max-width: 576px) {
    padding: 10px;
    font-size: 14px;
  }
`;

const Announcement = () => {
  const { db } = useFirebase();
  const [notices, setNotices] = useState([]);
  const [latestUpdate, setLatestUpdate] = useState(null);

  useEffect(() => {
    const fetchNotices = async () => {
      const today = new window.Date();
      today.setHours(0, 0, 0, 0);

      const noticesRef = collection(db, 'notices');
      const q = query(
        noticesRef,
        //where('endDate', '>', today),
        where('status', '==', 'active'),
        orderBy('endDate', 'asc')
      );

      try {
        const querySnapshot = await getDocs(q);
        const fetchedNotices = [];
        let latestDate = null;

        querySnapshot.forEach((doc) => {
          const data = doc.data();
          fetchedNotices.push(data.notice);
          
          if (!latestDate || data.createdOn.toDate() > latestDate) {
            latestDate = data.createdOn.toDate();
          }
        });
        console.log(fetchedNotices);
        setNotices(fetchedNotices);
        setLatestUpdate(latestDate);
      } catch (error) {
        console.error('Error fetching notices:', error);
      }
    };

    fetchNotices();
  }, [db]);

  const getIcon = (index) => {
    const icons = [Fa1, Fa2, Fa3, Fa4, Fa5, Fa6, Fa7, Fa8, Fa9, Fa0];
    return icons[index % 10];
  };

  return (
    <AnnouncementCard>
      <Title>Announcements</Title>
      {latestUpdate && (
        <Date>Last Updated: {latestUpdate.toLocaleDateString()}</Date>
      )}
      
      <AnnouncementList>
        {notices.map((notice, index) => {
          const IconComponent = getIcon(index);
          return (
            <AnnouncementItem key={index}>
              <Icon><IconComponent /></Icon>
              <AnnouncementText>{notice}</AnnouncementText>
            </AnnouncementItem>
          );
        })}
      </AnnouncementList>

      {notices.length === 0 && (
        <AnnouncementText>No current announcements.</AnnouncementText>
      )}
    </AnnouncementCard>
  );
};

export default Announcement;

/*import React from 'react';
import styled from 'styled-components';
import { Fa1, Fa2, Fa3, Fa4, Fa5, Fa6, Fa7, Fa8, Fa9, Fa0 } from "react-icons/fa6";

const AnnouncementCard = styled.div`
  background-color: var(--bodybgcolorwhite);
  border-radius: 12px;
  padding: 30px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  max-width: 600px;
  margin: 20px auto;

  @media (max-width: 576px) {
    padding: 20px;
  }
`;

const Title = styled.h1`
  text-align: center;
  font-size: 28px;
  margin-bottom: 20px;
  border-bottom: 2px solid var(--redcolor);
  padding-bottom: 10px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;

  @media (max-width: 576px) {
    font-size: 22px;
  }
`;

const Date = styled.h2`
  color: gray;
  text-align: left;
  font-size: 14px;
  margin-bottom: 20px;

  @media (max-width: 576px) {
    font-size: 12px;
  }
`;

const AnnouncementList = styled.ul`
  list-style-type: none;
  padding: 0;
`;

const AnnouncementItem = styled.li`
  margin-bottom: 20px;
  display: flex;
  align-items: flex-start;
`;

const Icon = styled.span`
  margin-right: 15px;
  color: red;
  font-size: 24px;
  flex-shrink: 0;

  @media (max-width: 576px) {
    font-size: 20px;
    margin-right: 10px;
  }
`;

const AnnouncementText = styled.p`
  margin: 0;
  font-size: 16px;
  text-align: left;

  @media (max-width: 576px) {
    font-size: 14px;
  }
`;

const ImportantNote = styled.div`
  background-color: var(--bodybgcolorgrey);
  border-left: 4px solid red;
  padding: 15px;
  margin-top: 20px;
  border-radius: 4px;

  @media (max-width: 576px) {
    padding: 10px;
    font-size: 14px;
  }
`;

const Announcement = ({ date, announcements, importantNote }) => {
  const getIcon = (index) => {
    const icons = [Fa1, Fa2, Fa3, Fa4, Fa5, Fa6, Fa7, Fa8, Fa9, Fa0];
    return icons[index % 10];
  };

  return (
    <AnnouncementCard>
      <Title>Announcements</Title>
      {date && <Date>Last Updated: {date}</Date>}
      
      <AnnouncementList>
        {announcements && announcements.map((announcement, index) => {
          const IconComponent = getIcon(index);
          return (
            <AnnouncementItem key={index}>
              <Icon><IconComponent /></Icon>
              <AnnouncementText>{announcement}</AnnouncementText>
            </AnnouncementItem>
          );
        })}
      </AnnouncementList>

      {importantNote && (
        <ImportantNote>
          <strong>Important Note:</strong> {importantNote}
        </ImportantNote>
      )}
    </AnnouncementCard>
  );
};

export default Announcement;
*/