import React from 'react';
import { Carousel } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './BannerCarouselComponent.css';
import SlideOneImage from "../../../images/sliders/s1.png";
import SlideTwoImage from "../../../images/sliders/s2.png";
import SlideThreeImage from "../../../images/sliders/s3.png";

//https://via.placeholder.com/1920x600?text=Slide+2
const BannerCarouselComponent = () => {
  return (

    <Carousel fade>
      <Carousel.Item>
       
            <img
                className="d-block w-100 carousel-overlay" 
                src={SlideOneImage}
                alt="First slide"
              />
         
        <Carousel.Caption>
          <h3 className='text-light'>First slide label</h3>
          <p className='text-light'>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
              className="d-block w-100"
              src={SlideTwoImage}
              alt="Second slide"
            />
        <Carousel.Caption>
          <h3 className='text-light'>Second slide label</h3>
          <p className='text-light'>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
            className="d-block w-100"
            src={SlideThreeImage}
            alt="Third slide"
          />
        <Carousel.Caption>
          <h3 className='text-light'>Third slide label</h3>
          <p className='text-light'>
            Praesent commodo cursus magna, vel scelerisque nisl consectetur.
          </p>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>

  );
};

export default BannerCarouselComponent;