import React from 'react';
import NavBarComponent from '../Components/GeneralNav/Nav';
import Footer from '../Components/Footer/Footer';
import { Container } from 'react-bootstrap';
import PageHeader from '../Layouts/PageHeader/PageHeader';
import HeaderImage from "../images/headers/s2.jpg"
import PastorsComponent from '../Layouts/Leadership/PastorsComponent';
import PresbyteryList from '../Layouts/Leadership/PresbyteryList';
import SessionList from '../Layouts/Leadership/SessionList';
import Admin from '../Layouts/Leadership/Admin';
import SEO from '../Components/SEO';

const Leadership = () => {
  return (
    <>
      <SEO
          title='Leadership'
          description='The official website of the Emmanuel Presbyterian Church Kasoa'
          name='Emmnuel Presbyterian Church.'
          type='profile' 
      />
      <NavBarComponent />
      <PageHeader 
        title="Leadership" 
        backgroundImage={HeaderImage}
       />
      <Container fluid style={{paddingLeft:"0px", paddingRight:"0px"}}>
        <div style={{height:"40px", width:"100%", backgroundColor: 'var(--redcolor)'}}>
            <p className='red-band' >MEET OUR LEADERSHIP AND TEAM </p>
        </div>
        <div>
          <PastorsComponent />
        </div>
        <div>
          <PresbyteryList />
        </div>
        <div>
          <SessionList />
        </div>
        <div>
          <Admin />
        </div>
      </Container>
      <Footer />
    </>
  );
};

export default Leadership;