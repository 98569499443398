import React, { useState, useEffect, useRef } from 'react';
import { Container, Row, Col, Spinner } from 'react-bootstrap';
import { collection, query, where, limit, getDocs } from 'firebase/firestore';
import { useFirebase } from '../../Context/FirebaseContext'; // Assume you have this context
import { FaFacebookF, FaTwitter, FaInstagram, FaLinkedinIn } from 'react-icons/fa';
import ButtonComponent from '../IndexLayout/Groups/ButtonComponent';

const PastorsComponent = () => {
  const [currentMinister, setCurrentMinister] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [expanded, setExpanded] = useState(false);
  const { db } = useFirebase();
  const profileRef = useRef(null);

  useEffect(() => {
    const fetchCurrentMinister = async () => {
      try {
        // Fetch current minister ID
        const currentMinisterQuery = query(collection(db, 'currentMinister'), limit(1));
        const currentMinisterSnapshot = await getDocs(currentMinisterQuery);
        
        if (currentMinisterSnapshot.empty) {
          setError('No current minister found');
          setLoading(false);
          return;
        }

        const currentMinisterData = currentMinisterSnapshot.docs[0].data();
        const ministerId = currentMinisterData.ministerId;

        // Fetch minister details
        const ministerQuery = query(collection(db, 'ministers'), where('id', '==', ministerId));
        const ministerSnapshot = await getDocs(ministerQuery);

        if (ministerSnapshot.empty) {
          setError('Minister details not found');
          setLoading(false);
          return;
        }

        setCurrentMinister(ministerSnapshot.docs[0].data());
        setLoading(false);
      } catch (err) {
        console.error('Error fetching data:', err);
        setError('Failed to fetch minister data');
        setLoading(false);
      }
    };

    fetchCurrentMinister();
  }, [db]);

  const truncateText = (text, maxLength) => {
    if (text.length <= maxLength) return text;
    return text.substr(0, maxLength) + '...';
  };

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  if (loading) {
    return (
      <Container className="d-flex justify-content-center align-items-center" style={{ height: '577px' }}>
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </Container>
    );
  }

  if (error) {
    return (
      <Container className="d-flex justify-content-center align-items-center" style={{ height: '577px' }}>
        <p>{error}</p>
      </Container>
    );
  }

  return (
    <div  style={{  margin:"50px 5%", overflow:"hidden"}}>
      <Row className="h-100" style={{textAlign:"left"}}>
        <Col sm={7} className="d-flex flex-column ">
          <h2 style={{marginBottom: "40px"}}>
            RESIDENT MINISTER
          </h2>
          
          <h3 >
            {currentMinister.name}
          </h3>
          <div style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '80px',
              marginBottom: '20px'
            }}>
              <FaFacebookF size={14}/>
              <FaTwitter size={14}  />
              <FaInstagram size={14}  />
              <FaLinkedinIn size={14} />
          </div>
          
          <p style={{
            lineHeight: '22px',
            color: '#000000',
            maxHeight: expanded ? 'none' : '88px', // 4 lines (22px * 4)
            overflow: 'hidden'
          }} ref={profileRef}>
            {expanded ? currentMinister.profile : truncateText(currentMinister.profile, 300)}
          </p>
          
          
          <span 
            style={{
              fontStyle: 'italic',
              fontSize: '12px',
              color: '#FA0523',
              marginBottom: '20px',
              cursor: 'pointer'
            }}
            onClick={toggleExpand}
          >
            {expanded ? 'Read less' : 'Read more'}
          </span>
          
          
          <ButtonComponent
            width="50%"
            height="40px"
            color="var(--txtcolor)"
            fontSize="16px"
          >
            Past Ministers
          </ButtonComponent>
        </Col>
        
        <Col sm={5} >
          <div >
            <img src={currentMinister.profilePicUrl} alt="resident minister" height="auto" width="100%" style={{maxWidth:'400px'}} />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default PastorsComponent;