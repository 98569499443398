import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { collection, query, where, getDocs, getDoc, doc } from 'firebase/firestore';
import { useFirebase } from '../Context/FirebaseContext';
import { Container, Row, Col, Spinner } from 'react-bootstrap';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import TeamMemberCard from '../Components/TeamMemberCard';
import SEO from '../Components/SEO';
import NavBarComponent from '../Components/GeneralNav/Nav';
import PageHeader from '../Layouts/PageHeader/PageHeader';
import HeaderImage from "../images/headers/s2.jpg";
import Footer from '../Components/Footer/Footer';
import ButtonComponent from '../Layouts/IndexLayout/Groups/ButtonComponent';
import MemberCard from '../Components/MemberCard';
import { useMediaQuery } from 'react-responsive';
import ScheduleCard from '../Layouts/Group/ScheduleCard';
import EventListing from '../Layouts/IndexLayout/Blog/EventListing';
import SlugGenerator from '../Components/Helpers/SlugGenerator';

const GroupPage = () => {
  const { groupId } = useParams();
  const { db } = useFirebase();
  const [groupData, setGroupData] = useState(null);
  const [leadershipData, setLeadershipData] = useState([]);
  const [upcomingEvents, setUpcomingEvents] = useState([]);
  const [leadershipError, setLeadershipError] = useState(false);
  const [loading, setLoading] = useState(true);  // Loading state
  const [noEvents, setNoEvents] = useState(false);  // No results state
  const isHideLogoBadge = useMediaQuery({ query: '(max-width: 991px)' });

  useEffect(() => {
    const fetchGroupData = async () => {
      try {
        setLoading(true);

        // Fetch group data
        const groupRef = collection(db, 'groups');
        const groupQuery = query(groupRef, where('id', '==', groupId));
        const groupSnapshot = await getDocs(groupQuery);

        if (!groupSnapshot.empty) {
          setGroupData(groupSnapshot.docs[0].data());
        }

        // Fetch leadership data
        const docRef = doc(db, 'leadership', groupId);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const leadershipDoc = docSnap.data();
          
          const tenures = leadershipDoc.tenures;

          if (tenures && Object.keys(tenures).length > 0) {
            const tenureEntries = Object.entries(tenures);
            tenureEntries.sort((a, b) => b[0] - a[0]);

            const mostRecentTenure = tenureEntries[0][1];
            setLeadershipData(mostRecentTenure.leadership || []);
          }
        }else{
          setLeadershipError(true);
        }

        // Fetch upcoming events
        const eventsRef = collection(db, 'events');
        const eventsQuery = query(
          eventsRef,
          where('group', '==', groupId),
          where('deleted', '==', false),
          where('status', '==', 'active'),
          where('startDate', '>=', new Date().toISOString().split('T')[0])
        );
        const eventsSnapshot = await getDocs(eventsQuery);

        const eventsList = eventsSnapshot.docs.map(doc => doc.data());
        
        setUpcomingEvents(eventsList);

        if (eventsList.length === 0) {
          setNoEvents(true);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchGroupData();
  }, [db, groupId]);

  function createSlug(string) {
    string = string.toLowerCase();
    string = string.replace(/[^a-z0-9 ]/g, '');
    string = string.replace(/\s+/g, '-');
    string = string.replace(/^-|-$/g, '');
    if (string === '') {
      return 'slug'; 
    }
  
    return string;
  }
  
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1
    }
  };

  return (
    <>
      <SEO
        title={groupData && groupData.name}
        description='The official website of the Emmanuel Presbyterian Church Kasoa'
        name='Group.'
        type='profile' 
      />
      <NavBarComponent />
      <PageHeader 
        title={groupData && groupData.name} 
        backgroundImage={HeaderImage}
      />
      <div style={{height:"40px", width:"100%", backgroundColor: 'var(--redcolor)', position:"absolute"}} >
        <p className='truncate-text' style={{textAlign:"left", paddingLeft: "15px",  paddingRight:"15px",paddingTop:"10px", color:"whitesmoke", fontWeight:600}}>MEET OUR LEADERS AND KNOW OUR ACTIVITIES</p>

        {!isHideLogoBadge ? 
          <div  style={{position:"relative", top: "-190px", right:"-70%", height:"200px", width:"200px", background:"white",paddingTop:"10px"}}>
            <img src={groupData && groupData.profilePicUrl} alt="group logo" height="180px" width="auto"/>
          </div>
          :
          ""
        }
      </div>
      <Container>
        {groupData && (
          <Row className="my-5" >
            <h2 className="text-dark" style={{textAlign:"left", marginTop:"50px"}}>Introduction</h2>
            <Col>
              <p style={{textAlign:"left"}}>{groupData.description}</p>
            </Col>
          </Row>
        )}
      </Container>
      {!leadershipError &&  
      <Container fluid className="presbytery-list bg-dark text-light py-5">
        <Row className="justify-content-between align-items-center mb-4">
          <Col style={{paddingLeft:"5%"}}>
            <h2 className="text-light" style={{textAlign:"left"}}>Executives</h2>
            <h3 className="text-danger" style={{textAlign:"left"}}>Current</h3>
          </Col>
          <Col xs="auto" style={{ paddingRight:"50px"}}>
            <ButtonComponent
              width="200px"
              height="50px"
              color="var(--txtcolor)"
              fontSize="16px"
              to='pastleaders'
            >
              Past Executives
            </ButtonComponent>
          </Col>
        </Row> 
        
        <div style={{paddingLeft:"5%", paddingRight:"5%"}}>
          <Carousel
            swipeable={false}
            draggable={false}
            showDots={true}
            responsive={responsive}
            ssr={true}
            infinite={true}
            autoPlay={false}
            autoPlaySpeed={1000}
            keyBoardControl={true}
            customTransition="all .5"
            transitionDuration={500}
            containerClass="carousel-container"
            removeArrowOnDeviceType={["tablet", "mobile"]}
            dotListClass="custom-dot-list-style"
            itemClass="carousel-item-padding-40-px"
          >
            {leadershipData.map((leader, index) => (
              <MemberCard key={index} memberId={leader.memberId} />
            ))}
          </Carousel>
        </div>
      </Container> 
      }

      <Container fluid className="presbytery-list bg-light text-dark py-5">
        <ScheduleCard groupId={groupId} />
      </Container>
      
      {loading ? (
        <Spinner animation="border" variant="primary" />
        ) : noEvents ? (
          <p></p>
        ) : (
        <Container fluid className="presbytery-list bg-light text-dark py-5">
          <Row className="justify-content-between align-items-left mb-4">
            <Col sm={12} style={{paddingLeft:"5%"}}>
              <h2 className="text-dark" style={{textAlign:"left"}}>Events</h2>
              <h3 className="text-danger" style={{textAlign:"left"}}>Upcoming group events</h3>
            </Col>
            <Col sm={12} style={{paddingLeft:"5%", paddingRight:"5%", marginTop:"20px"}}>
              
              { upcomingEvents.map((item, index) => (
                  <EventListing 
                    key={index}
                    month={item.startDate}
                    day={item.startDate}
                    title={item.eventName}
                    href={`/events/${createSlug(item.eventName)}/${item.group}`}
                    startDate={item.startDate}
                    endDate={item.endDate}
                  />
                ))}
              
            </Col>
          </Row>
        </Container>
        )}

      <Footer/>
    </>
  );
};

export default GroupPage;
