import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useFirebase } from '../../../Context/FirebaseContext';
import { collection, query, where, getDocs, orderBy, limit } from 'firebase/firestore';
import DateFormater from '../../../Components/Helpers/DateFormater';
import FetchNameById from '../../../Components/Helpers/FetchNameById';


const ServiceCard = styled.div`
  background-color: #fff;
  border-radius: 12px;
  padding: 30px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  max-width: 500px;
  margin: 20px auto;

  @media (max-width: 576px) {
    padding: 20px;
  }
`;

const Box = styled.div`
  background-color: #fff;
`;

const Title = styled.h1`
  text-align: center;
  font-size: 28px;
  margin-bottom: 20px;
  border-bottom: 2px solid var(--redcolor);
  padding-bottom: 10px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;

  @media (max-width: 576px) {
    font-size: 22px;
  }
`;

const Date = styled.h2`
  text-align: center;
  font-size: 24px;
  margin-bottom: 20px;

  @media (max-width: 576px) {
    font-size: 20px;
  }
`;

const TimingSection = styled.div`
  background-color: #E6E6FA;
  padding: 15px;
  border-radius: 8px;
  margin-bottom: 20px;

  @media (max-width: 576px) {
    padding: 10px;
  }
`;

const Timing = styled.p`
  margin: 5px 0;
  font-size: 16px;

  @media (max-width: 576px) {
    font-size: 14px;
  }
`;

const Section = styled.div`
  margin-bottom: 20px;
`;

const SectionTitle = styled.h3`
  margin-bottom: 10px;
  font-size: 20px;
  border-bottom: 1px solid var(--bodybgcolorgrey);
  padding-bottom: 5px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;

  @media (max-width: 576px) {
    font-size: 18px;
  }
`;

const SectionContent = styled.p`
  margin: 5px 0;
  font-size: 16px;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;

  @media (max-width: 576px) {
    font-size: 14px;
  }
`;

const BibleReading = styled(SectionContent)`
  font-style: italic;
`;

const MemoryVerse = styled(SectionContent)`
  font-weight: bold;
  text-align: center;
  margin-top: 15px;
  font-size: 18px;
  color: var(--redcolor);

  @media (max-width: 576px) {
    font-size: 16px;
  }
`;

const VerseSection = styled.p`
  margin: 5px 0;
  font-size: 16px;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;

  @media (max-width: 576px) {
    font-size: 14px;
  }
`;

const verse = styled(SectionContent)`
  font-weight: bold;
  text-align: center;
  margin-top: 15px;
  font-size: 18px;
  color: var(--redcolor);

  @media (max-width: 576px) {
    font-size: 16px;
  }
`;
const responsive = {
  desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
  },
  tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
  },
  mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
  }
};

const UpcomingService = () => {

  const { db } = useFirebase();
  const [serviceData, setServiceData] = useState(null);

  useEffect(() => {
    const fetchUpcomingService = async () => {
      const today = new window.Date();
      today.setHours(0, 0, 0, 0);
      const todayString = today.toISOString().split('T')[0];
      //console.log(today);
      const servicesRef = collection(db, 'services');
      const q = query(
        servicesRef,
        where('date', '>=', todayString),
        orderBy('date'),
        limit(1)
      );

      try {
        const querySnapshot = await getDocs(q);
        if (!querySnapshot.empty) {
          const doc = querySnapshot.docs[0];
          //console.log(doc.data());
          setServiceData(doc.data());
        } else {
          console.log('No upcoming services found');
        }
      } catch (error) {
        console.error('Error fetching upcoming service:', error);
      }
    };

    fetchUpcomingService();
  }, [db]);

  if (!serviceData) {
    return <ServiceCard><Title>Loading upcoming service...</Title></ServiceCard>;
  }


  return (
    <ServiceCard>
      <Title>Upcoming Service</Title>
      <Date>{<DateFormater date={serviceData.date} />}</Date>

      <Section>
        <SectionTitle>THEME</SectionTitle>
        <SectionContent >{serviceData.theme}</SectionContent>
      </Section>

      <Carousel 
        responsive={responsive}
        swipeable={true}
        draggable={false}
        infinite={true}
        autoPlay={false}
      >
        {serviceData.sessions.map((item, index)=>(
          <Box key={index}>
            <TimingSection>
              <Timing><strong>Start: </strong>{item.startTime}</Timing>
              <Timing><strong>Close: </strong> {item.endTime}</Timing>
            </TimingSection>

            <Section>
              <SectionTitle>LITURGY</SectionTitle>
              <SectionContent>{item.liturgy}</SectionContent>
            </Section>

            {item.activities.map((activity, index)=>(
              <Section key={index}>
                <SectionTitle>{activity.name}</SectionTitle>
                <SectionContent><FetchNameById collectionName={'members'} documentId={activity.leaderId}/></SectionContent>
                <SectionContent>{activity.description}</SectionContent>
              </Section>
            ))}

            
            <MemoryVerse>Memory Verse</MemoryVerse>
            <VerseSection>{serviceData.memVerse}</VerseSection>
          </Box>
        ))}
      </Carousel>
    </ServiceCard>
  );
};



export default UpcomingService;
