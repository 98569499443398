import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button, Spinner } from 'react-bootstrap';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import TeamMemberCard from './TeamMemberCard';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { useFirebase } from '../../Context/FirebaseContext';
import ButtonComponent from '../IndexLayout/Groups/ButtonComponent';

const PresbyteryList = () => {
  const [presbyters, setPresbyters] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { db } = useFirebase();

  useEffect(() => {
    const fetchPresbyters = async () => {
      try {
        // First, get the presbytery group
        const groupsQuery = query(collection(db, 'groups'), where('type', '==', 'presbyters'));
        const groupSnapshot = await getDocs(groupsQuery);
        
        if (groupSnapshot.empty) {
          setError('No presbytery group found');
          setLoading(false);
          return;
        }

        const presbyteryGroupId = groupSnapshot.docs[0].id;

        // Then, get all members in this group
        const membersQuery = query(
          collection(db, 'members'), 
          where('group', 'array-contains', presbyteryGroupId)
        );
        const membersSnapshot = await getDocs(membersQuery);

        const presbytersData = membersSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
          role: 'Presbyter' // You might want to fetch this from another field if available
        }));

        setPresbyters(presbytersData);
      } catch (err) {
        console.error("Error fetching presbyters:", err);
        setError('Failed to fetch presbyters');
      } finally {
        setLoading(false);
      }
    };

    fetchPresbyters();
  }, [db]);

  const responsive = {
    desktop: { breakpoint: { max: 3000, min: 1024 }, items: 3 },
    tablet: { breakpoint: { max: 1024, min: 464 }, items: 2 },
    mobile: { breakpoint: { max: 464, min: 0 }, items: 1 }
  };

  if (loading) {
    return (
      <Container className="d-flex justify-content-center align-items-center" style={{ height: '200px' }}>
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </Container>
    );
  }

  if (error) {
    return (
      <Container className="text-center mt-5">
        <h3>Error: {error}</h3>
      </Container>
    );
  }

  if (presbyters.length === 0) {
    return (
      <Container className="text-center mt-5">
        
      </Container>
    );
  }

  function capitalizeFirstLetter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  return (
    <Container fluid className="presbytery-list bg-dark text-light py-5">
      <Row className="justify-content-between align-items-center mb-4">
        <Col style={{paddingLeft:"5%", paddingRight:'5%'}}>
          <h2 className="text-light" style={{textAlign:"left"}}>PRESBYTERS</h2>
          <h3 className="text-danger" style={{textAlign:"left"}}>Current</h3>
        </Col>
        <Col xs="auto" style={{ paddingRight:"50px"}}>
          <ButtonComponent
            width="100%"
            height="50px"
            backgroundColor="none"
            color="var(--txtcolor)"
            fontSize="16px"
          >
            Past Presbyters
          </ButtonComponent>
        </Col>
      </Row>
      <Carousel
        responsive={responsive}
        infinite={true}
        autoPlay={true}
        autoPlaySpeed={3000}
        keyBoardControl={true}
        customTransition="all .5"
        transitionDuration={500}
        containerClass="carousel-container"
        removeArrowOnDeviceType={["tablet", "mobile"]}
        dotListClass="custom-dot-list-style"
        itemClass="carousel-item-padding-40-px"
      >
        {presbyters.map((presbyter) => (
          <div key={presbyter.id} className="d-flex justify-content-center">
            <TeamMemberCard
              name={`${capitalizeFirstLetter(presbyter.title)} ${presbyter.firstName} ${presbyter.lastName}`}
              role={presbyter.role}
              imageUrl={presbyter.profilePicUrl}
            />
          </div>
        ))}
      </Carousel>
    </Container>
  );
};

export default PresbyteryList;