import React from 'react';

const DateFormater = ({ date }) => {
  const dateObject = new Date(date);
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  const month = monthNames[dateObject.getMonth()];
  const day = dateObject.getDate();
  const year = dateObject.getFullYear();

  return (
    <span>
      {month} {day}, {year}
    </span>
  );
};

export default DateFormater;