import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button, Spinner } from 'react-bootstrap';
import { useMediaQuery } from 'react-responsive';
import './Welcome.css';
import { useFirebase } from '../../../Context/FirebaseContext';
import { doc, getDoc } from 'firebase/firestore';
import LazyLoad from 'react-lazyload';

const Welcome = () => {
  const [welcome, setWelcome] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [expanded, setExpanded] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(false);
  const { db } = useFirebase();

  const isSmallScreen = useMediaQuery({ query: '(max-width: 576px)' });

  useEffect(() => {
    const fetchWelcomeMetadata = async () => {
      try {
        setLoading(true);
        const welcomeDoc = await getDoc(doc(db, 'welcomeNote', 'current'));
        if (welcomeDoc.exists()) {
          setWelcome(welcomeDoc.data());
        } else {
          setError('No welcome data found');
        }
      } catch (error) {
        console.error("Error fetching welcome data:", error);
        setError('Failed to fetch welcome data');
      } finally {
        setLoading(false);
      }
    };

    fetchWelcomeMetadata();
  }, [db]);

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  const truncateText = (text, maxLength) => {
    if (text.length <= maxLength) return text;
    return text.substr(0, maxLength) + '...';
  };

  if (loading) {
    return (
      <Container className="text-center mt-5">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </Container>
    );
  }

  if (error) {
    return (
      <Container className="text-center mt-5">
        <p className="text-danger">{error}</p>
      </Container>
    );
  }

  if (!welcome) {
    return (
      <Container className="text-center mt-5">
        <h3>We are honoured to have you here.</h3>
      </Container>
    );
  }

  return (
    <Container style={{ width: "100%", overflowX: "hidden" }}>
      <Row>
        <Col xs={12} sm={6} className="welcome-text">
          <p className={`welcome-title ${isSmallScreen ? 'small' : ''}`}>
            {welcome.noteTitle}
          </p>
          <div className={`welcome-message ${isSmallScreen ? 'small' : ''}`}>
            <div dangerouslySetInnerHTML={{ 
              __html: expanded ? welcome.welcomeNote : truncateText(welcome.welcomeNote, 500) 
            }} />
            {welcome.welcomeNote.length > 500 && (
              <Button 
                variant="link" 
                onClick={toggleExpand} 
                className="p-0 text-decoration-none red-color"
              >
                {expanded ? 'Show Less' : 'Read More'}
              </Button>
            )}
          </div>
          <p className={`welcome-signature ${isSmallScreen ? 'small' : ''}`}>
            {welcome.leaderName}
          </p>
          <p className={`welcome-position ${isSmallScreen ? 'small' : ''}`}>
            {welcome.leaderTitle}
          </p>
        </Col>
        <Col xs={12} sm={6}>
          <LazyLoad height={300} once>
            {!imageLoaded && <Spinner animation="border" role="status" />}
            <img 
              src={welcome.leaderImageUrl} 
              alt="Pastor" 
              className={`pastor-img ${imageLoaded ? 'loaded' : 'loading'}`}
              onLoad={() => setImageLoaded(true)}
              style={{ display: imageLoaded ? 'block' : 'none' }}
            />
          </LazyLoad>
        </Col>
      </Row>
    </Container>
  );
};

export default Welcome;